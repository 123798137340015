import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  LinkOutlined,
  MailOutlined,
  UndoOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Alert, Button, Col, List, Modal, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import InformationCard from '../../bookingOrders/components/InformationCard';
import {
  CourseInstanceCardData,
  CourseInstanceStatus,
  DisplayedCourseInstance,
} from '../types/CourseInstance';
import ClientCompany from '../../bookingOrders/types/ClientCompany';
import ContactPerson from '../../bookingOrders/types/ContactPerson';
import ActionPopoverButton, {
  ActionPopoverElement,
} from '../../components/ActionPopoverButton';
import Gravatar from '../../user/components/Gravatar';
import * as DateUtils from '../../utils/date';
import CourseInstanceAPI from '../CourseInstanceAPI';
import { getFullContactName } from '../../bookingOrders/utils/contactPerson';

import CourseInstanceAssignmentModal from './CourseInstanceAssignmentModal';
import CourseInstanceStatusBadge from './CourseInstanceStatusBadge';
import EditCourseInstance from './EditCourseInstance';
import CourseInstanceAttendeeFooter from './CourseInstanceAttendeeFooter';
import { useFeatureToggles } from '../../context/featureToggles';
import { useQuery } from '@tanstack/react-query';

type CourseInstanceCardProps = {
  displayedCourseInstance: DisplayedCourseInstance;
  bookingOrderClientCompany?: ClientCompany;
  bookingOrderContactPerson?: ContactPerson;
  onCourseInstanceUpdate?: (
    displayedCourseInstance: DisplayedCourseInstance,
  ) => void;
  submittingCourseInstance?: boolean;
  onDelete?: (displayedCourseInstance: DisplayedCourseInstance) => void;
  openEmailModal?: () => void;
  previewOnly?: boolean;
};

const CourseInstanceCard: React.FC<CourseInstanceCardProps> = ({
  displayedCourseInstance,
  bookingOrderClientCompany,
  bookingOrderContactPerson,
  onCourseInstanceUpdate,
  submittingCourseInstance,
  onDelete,
  openEmailModal,
  previewOnly,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAssignInstructorModal, setShowAssignInstructorModal] =
    useState(false);
  const [totalMileage, setTotalMileage] = useState(0);
  const [loadingCourseInstances, setLoadingCourseInstances] = useState(false);
  const [courseInstances, setCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const [uncancelModalOpen, setUncancelModalOpen] = useState(false);
  const { t } = useTranslation();
  const { isElsaIntegrationEnabled } = useFeatureToggles();

  useEffect(() => {
    setTotalMileage(
      (displayedCourseInstance.mileagePrice ?? 0) *
        (displayedCourseInstance.mileageQuantity ?? 0),
    );
  }, [displayedCourseInstance]);

  const attendeesQueryKey = [
    'getCourseInstanceAttendees',
    displayedCourseInstance.id,
  ];
  const attendeesQuery = useQuery({
    queryKey: attendeesQueryKey,
    queryFn: () => {
      if (displayedCourseInstance.id) {
        return CourseInstanceAPI.getAttendees(displayedCourseInstance.id);
      }
    },
    enabled: !!displayedCourseInstance.id,
    retry: false,
  });

  const fetchCourseInstances = useCallback(async () => {
    setLoadingCourseInstances(true);
    try {
      const { data } = await CourseInstanceAPI.getCourseInstances({
        instructorIds: [displayedCourseInstance.instructor?.id ?? null],
        startsAt: dayjs(displayedCourseInstance.startsAt)
          .startOf('day')
          .toISOString(),
        endsAt: dayjs(displayedCourseInstance.endsAt)
          .endOf('day')
          .toISOString(),
        status: [
          CourseInstanceStatus.Confirmed,
          CourseInstanceStatus.Ordered,
          CourseInstanceStatus.Preliminary,
        ],
      });
      setCourseInstances(data.data);
    } finally {
      setLoadingCourseInstances(false);
    }
  }, [
    displayedCourseInstance.endsAt,
    displayedCourseInstance.instructor?.id,
    displayedCourseInstance.startsAt,
  ]);

  const updateCourseInstance = useCallback(
    (displayedCourseInstance: DisplayedCourseInstance) => {
      onCourseInstanceUpdate?.(displayedCourseInstance);
      setShowAssignInstructorModal(false);
    },
    [onCourseInstanceUpdate],
  );

  const openAssignInstructorModal = useCallback(() => {
    setShowAssignInstructorModal(true);
  }, []);

  const setCourseInstanceConfirmed = useCallback(
    (confirmed: boolean) => {
      const updatedCourseInstance = {
        ...displayedCourseInstance,
        confirmed,
      };
      onCourseInstanceUpdate?.(updatedCourseInstance);
    },
    [displayedCourseInstance, onCourseInstanceUpdate],
  );

  const setCourseInstanceCanceled = useCallback(
    (canceled: boolean) => {
      const updatedCourseInstance = {
        ...displayedCourseInstance,
        canceled,
      };
      onCourseInstanceUpdate?.(updatedCourseInstance);
      setUncancelModalOpen(false);
    },
    [displayedCourseInstance, onCourseInstanceUpdate],
  );

  const openDeletionModal = useCallback(() => {
    Modal.confirm({
      centered: true,
      title: t('components.CourseInstanceCard.deleteCourseInstance'),
      icon: <DeleteOutlined className="text-safeLifeWarning" />,
      content: t('components.CourseInstanceCard.confirmDeleteCourseInstance'),
      onOk: () => onDelete?.(displayedCourseInstance),
      okText: t('common.delete'),
      closable: true,
      cancelText: t('common.cancel'),
    });
  }, [displayedCourseInstance, onDelete, t]);

  const openCancellationModal = useCallback(() => {
    Modal.confirm({
      centered: true,
      title: t('components.CourseInstanceCard.cancelCourseInstance'),
      icon: <CloseCircleOutlined className="text-safeLifeWarning" />,
      content: (
        <>
          <div>
            {t('components.CourseInstanceCard.confirmCancelCourseInstance')}
          </div>
          <div className="pt-2">
            {t('components.CourseInstanceCard.setPriceZero', {
              currency: displayedCourseInstance.currency.code,
            })}
          </div>
        </>
      ),
      onOk: () => setCourseInstanceCanceled(true),
      okText: t('components.CourseInstanceCard.cancel'),
      closable: true,
      cancelText: t('common.cancel'),
    });
  }, [displayedCourseInstance.currency.code, setCourseInstanceCanceled, t]);

  const openUndoCancellationModal = useCallback(() => {
    fetchCourseInstances();
    setUncancelModalOpen(true);
  }, [fetchCourseInstances]);

  const actionPopoverElements = useMemo<ActionPopoverElement[]>(
    () => [
      {
        rowElement: (
          <div>
            {displayedCourseInstance.status ===
            CourseInstanceStatus.Confirmed ? (
              <div>
                <UndoOutlined className="pr-2" />
                {t('components.CourseInstanceCard.unconfirm')}
              </div>
            ) : (
              <div>
                <CheckOutlined className="pr-2" />
                {t('common.confirm')}
              </div>
            )}
          </div>
        ),
        action: () => {
          if (
            displayedCourseInstance.status === CourseInstanceStatus.Confirmed
          ) {
            setCourseInstanceConfirmed(false);
          } else {
            setCourseInstanceConfirmed(true);
          }
        },
        hidden:
          !displayedCourseInstance.id ||
          !displayedCourseInstance.startsAt ||
          !displayedCourseInstance.endsAt ||
          !displayedCourseInstance.instructor,
      },
      {
        rowElement: (
          <div>
            <EditOutlined className="pr-2" />
            {t('common.edit')}
          </div>
        ),
        action: () => setShowEditModal(true),
      },
      {
        rowElement: (
          <div>
            <UserSwitchOutlined className="pr-2" />
            {t('components.CourseInstanceCard.pickTimeAndInstructor')}
          </div>
        ),
        action: () => {
          openAssignInstructorModal();
        },
      },
      {
        rowElement: (
          <div>
            <MailOutlined className="pr-2" />
            {t('components.CourseInstanceCard.sendConfirmation')}
          </div>
        ),
        action: openEmailModal || (() => {}),
        hidden:
          !displayedCourseInstance.id ||
          !displayedCourseInstance.startsAt ||
          !displayedCourseInstance.endsAt ||
          !displayedCourseInstance.instructor,
      },
      {
        rowElement: (
          <div>
            {displayedCourseInstance.status ===
            CourseInstanceStatus.Canceled ? (
              <>
                <UndoOutlined className="pr-2" />
                {t('components.CourseInstanceCard.undoCancellation')}
              </>
            ) : (
              <>
                <CloseCircleOutlined className="pr-2" />
                {t('components.CourseInstanceCard.cancel')}
              </>
            )}
          </div>
        ),
        action: () => {
          if (
            displayedCourseInstance.status === CourseInstanceStatus.Canceled
          ) {
            openUndoCancellationModal();
          } else {
            openCancellationModal();
          }
        },
        hidden: !displayedCourseInstance.id,
      },
      {
        rowElement: (
          <div>
            <DeleteOutlined className="pr-2" />
            {t('common.delete')}
          </div>
        ),
        action: () => openDeletionModal(),
      },
    ],
    [
      displayedCourseInstance.endsAt,
      displayedCourseInstance.id,
      displayedCourseInstance.instructor,
      displayedCourseInstance.startsAt,
      displayedCourseInstance.status,
      openAssignInstructorModal,
      openCancellationModal,
      openDeletionModal,
      openEmailModal,
      openUndoCancellationModal,
      setCourseInstanceConfirmed,
      t,
    ],
  );

  return (
    <div className="rounded mt-6 shadow-md border-solid border-gray-200 border-1">
      <Modal
        open={uncancelModalOpen}
        centered
        title={
          <div className="flex">
            <UndoOutlined className="text-xl mr-3 text-safeLifeWarning" />
            <span className="ant-modal-confirm-title">
              {t('components.CourseInstanceCard.uncancelCourseInstance')}
            </span>
          </div>
        }
        onOk={() => setCourseInstanceCanceled(false)}
        okText={t('components.CourseInstanceCard.undoCancellation')}
        okButtonProps={{
          className:
            'bg-confirmModal-200 hover:bg-confirmModal-300 active:bg-confirmModal-100 outline-confirmModal-400',
        }}
        closable={true}
        cancelText={t('common.cancel')}
        cancelButtonProps={{
          className:
            'hover:border-confirmModal-300 hover:text-confirmModal-300 active:border-confirmModal-100 active:text-confirmModal-100 outline-confirmModal-400',
        }}
        onCancel={() => setUncancelModalOpen(false)}
        footer={loadingCourseInstances ? <></> : undefined}>
        <>
          <div>
            {t('components.CourseInstanceCard.confirmUncancelCourseInstance')}
          </div>
          {courseInstances.length > 0 && (
            <Alert
              type="info"
              showIcon
              message={t(
                'components.CourseInstanceCard.instructorAlreadyBookedInstances',
                { instructorName: displayedCourseInstance.instructor?.name },
              )}
              description={
                <List<CourseInstanceCardData>
                  loading={loadingCourseInstances}
                  dataSource={courseInstances}
                  renderItem={(courseInstance) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <Link
                            to={`/bestallningar/${courseInstance.orderId}`}
                            target="_blank"
                            rel="norefferer noopener">
                            {courseInstance.course?.name}{' '}
                            <ExportOutlined className="mt-2" />
                          </Link>
                        }
                        description={
                          courseInstance.startsAt &&
                          courseInstance.endsAt && (
                            <>
                              {DateUtils.formatDate(courseInstance.startsAt)},{' '}
                              {DateUtils.formatTime(courseInstance.startsAt)} -{' '}
                              {DateUtils.formatTime(courseInstance.endsAt)}
                              <br />
                              {courseInstance.address}
                            </>
                          )
                        }
                      />
                      <CourseInstanceStatusBadge
                        status={courseInstance.status}
                      />
                    </List.Item>
                  )}
                />
              }
            />
          )}
        </>
      </Modal>
      <div className="p-4">
        <div className="flex flex-row justify-between items-center border-solid border-gray-200 border-0 border-b-2 pb-3 px-2">
          <div className="flex flex-row items-center">
            <div className="mr-3 font-medium text-lg">
              {displayedCourseInstance.course.name}
            </div>
            <CourseInstanceStatusBadge
              status={displayedCourseInstance.status}
            />
          </div>
          {isElsaIntegrationEnabled &&
            displayedCourseInstance.elsaTrainingSessionUrl && (
              <div className="ml-auto mr-6 flex flex-row items-center gap-6">
                <Button
                  onClick={() =>
                    window.open(
                      displayedCourseInstance.elsaTrainingSessionUrl,
                      '_blank',
                    )
                  }
                  icon={<LinkOutlined />}>
                  {t('components.CourseInstanceCard.viewInElsa')}
                </Button>
                <div className="flex flex-row items-center gap-2">
                  <CheckOutlined className="text-safeLifeSuccess" />
                  <span className="text-md">
                    {t('components.CourseInstanceCard.synced')}
                  </span>
                </div>
              </div>
            )}
          {!previewOnly && (
            <div className="flex flex-row gap-4 items-center pr-5">
              <ActionPopoverButton
                actionPopoverElements={actionPopoverElements}
              />
            </div>
          )}
        </div>
        <div>
          <Row gutter={[16, 16]} className="px-2 pt-5 pb-3">
            <Col xs={12} sm={8}>
              <InformationCard
                title={t('components.CourseInstanceCard.visitingAddress')}>
                <div className="gap-y-1 pt-1">
                  <div>{displayedCourseInstance.address || '-'}</div>
                </div>
              </InformationCard>
            </Col>
            <Col xs={12} sm={8}>
              <InformationCard title={t('common.time')}>
                <div className="gap-y-1 pt-1">
                  <div>
                    {displayedCourseInstance.startsAt ? (
                      <>
                        <div>
                          {dayjs(displayedCourseInstance.startsAt).format(
                            'DD MMMM YYYY',
                          )}
                        </div>
                        <div>
                          {dayjs(displayedCourseInstance.startsAt).format(
                            'HH:mm',
                          )}{' '}
                          -{' '}
                          {dayjs(displayedCourseInstance.endsAt).format(
                            'HH:mm',
                          )}
                        </div>
                      </>
                    ) : (
                      ['-']
                    )}
                  </div>
                </div>
              </InformationCard>
            </Col>
            <Col xs={12} sm={8}>
              <InformationCard title={t('common.instructor')}>
                <div className="flex flex-row items-center mt-2">
                  <Gravatar
                    isInstructor
                    email={displayedCourseInstance.instructor?.email}
                    internal={displayedCourseInstance.instructor?.internal}
                  />
                  <div className="ml-2 font-medium">
                    {displayedCourseInstance.instructor
                      ? displayedCourseInstance.instructor.name
                      : t('components.CourseInstanceCard.noInstructor')}
                  </div>
                </div>
              </InformationCard>
            </Col>
            <Col xs={12} sm={8}>
              <InformationCard
                title={t('components.CourseInstanceCard.contactPerson')}>
                <div className="gap-y-1 pt-1">
                  {displayedCourseInstance.contactPerson ? (
                    <>
                      <div>
                        {getFullContactName(
                          displayedCourseInstance.contactPerson,
                        )}
                      </div>
                      <div>{displayedCourseInstance.contactPerson.email}</div>
                      <div>
                        {displayedCourseInstance.contactPerson.phoneNumber}
                      </div>
                    </>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </InformationCard>
            </Col>
            <Col xs={12} sm={8}>
              {(displayedCourseInstance.managerEmails?.length ?? 0) > 0 && (
                <InformationCard title="Managers">
                  <div className="gap-y-1 pt-1">
                    <div>
                      {displayedCourseInstance.managerEmails?.map((m) => (
                        <div key={m}>{m}</div>
                      ))}
                    </div>
                  </div>
                </InformationCard>
              )}
            </Col>
            <Col xs={12} sm={8}>
              <InformationCard title={t('common.price')}>
                <div className="flex flex-col gap-y-1 pt-1">
                  <span>
                    {t('components.CourseInstanceCard.coursePrice')}:{' '}
                    {displayedCourseInstance.price ?? '0'}{' '}
                    {displayedCourseInstance.currency.code}
                  </span>
                  {totalMileage > 0 && (
                    <span>
                      {t('common.mileage')}: {totalMileage}{' '}
                      {displayedCourseInstance.currency.code}
                    </span>
                  )}
                </div>
              </InformationCard>
            </Col>
            <Col xs={24}>
              <InformationCard title={t('common.note')}>
                <div className="gap-y-1 pt-1">
                  <span className="break-words whitespace-pre-line">
                    {displayedCourseInstance.comment || '-'}
                  </span>
                </div>
              </InformationCard>
            </Col>
          </Row>

          {showEditModal && (
            <Modal
              open
              width={'80%'}
              bodyStyle={{ paddingTop: 0 }}
              onCancel={() => setShowEditModal(false)}
              title={
                <div className="h-5">
                  {t('components.CourseInstanceCard.editCourseInstance')}
                  {submittingCourseInstance && <Spin className="pl-4" />}
                </div>
              }
              footer={null}>
              <EditCourseInstance
                displayedCourseInstance={displayedCourseInstance}
                bookingOrderClientCompany={bookingOrderClientCompany}
                bookingOrderContactPerson={bookingOrderContactPerson}
                onCourseInstanceUpdate={onCourseInstanceUpdate}
              />
            </Modal>
          )}
          {showAssignInstructorModal && (
            <CourseInstanceAssignmentModal
              visible
              clientCompany={bookingOrderClientCompany}
              contactPerson={bookingOrderContactPerson}
              onCancel={() => setShowAssignInstructorModal(false)}
              updateDisplayedCourseInstance={updateCourseInstance}
              displayedCourseInstance={displayedCourseInstance}
            />
          )}
        </div>
      </div>
      {!previewOnly && (
        <CourseInstanceAttendeeFooter
          courseInstance={displayedCourseInstance}
          attendeesQuery={attendeesQuery}
        />
      )}
    </div>
  );
};

export default CourseInstanceCard;
