import React, { useState } from 'react';
import {
  DownOutlined,
  ExclamationCircleFilled,
  MinusCircleOutlined,
  UserDeleteOutlined,
  UsergroupDeleteOutlined,
  EllipsisOutlined,
  EditOutlined,
  ImportOutlined,
  DownloadOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import {
  Empty,
  MenuProps,
  Modal,
  Button,
  Table,
  Dropdown,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useCSVReader, usePapaParse } from 'react-papaparse';
import { ParseResult } from 'papaparse';
import { AxiosResponse, isAxiosError } from 'axios';
import CourseInstanceAPI from '../CourseInstanceAPI';
import CourseInstance, {
  CourseInstanceAttendee,
  CourseInstanceAttendeeStatus,
  DisplayedCourseInstance,
} from '../types/CourseInstance';
import colors from '../../theme/colors';
import { UseQueryResult } from '@tanstack/react-query';

import CreateCourseInstanceAttendeeModal from './CreateCourseInstanceAttendeeModal';
import EditCourseInstanceAttendeeModal from './EditCourseInstanceAttendeeModal';
import DownloadCertificateDropdown from './DownloadCertificateDropdown';
import { CourseInstanceAttendeeStatusBox } from './CourseInstanceAttendeeStatusBox';

type CourseInstanceAttendanceListModalProps = {
  id: CourseInstance['id'];
  courseInstance: DisplayedCourseInstance;
  attendeesQuery: UseQueryResult<
    AxiosResponse<CourseInstanceAttendee[]> | undefined
  >;
  open: boolean;
  onClose: () => void;
};

const CourseInstanceAttendanceListModal: React.FC<
  CourseInstanceAttendanceListModalProps
> = ({ id, courseInstance, attendeesQuery, open, onClose }) => {
  const attendees = attendeesQuery.data?.data;

  const [selectedAttendees, setSelectedAttendees] = useState<
    CourseInstanceAttendee[]
  >([]);
  const [showCreateAttendeeModal, setShowCreateAttendeeModal] = useState(false);
  const [editAttendee, setEditAttendee] = useState<CourseInstanceAttendee>();
  const { t } = useTranslation();
  const { jsonToCSV } = usePapaParse();
  const { CSVReader } = useCSVReader();

  const isMappedInElsa = !!courseInstance.elsaTrainingSessionId;

  type ExportedCourseInstanceAttendee = {
    id: number;
    name: string;
    email?: string;
    status?: CourseInstanceAttendeeStatus;
    comment?: string;
  };

  const showConfirmDeletion = () => {
    Modal.confirm({
      title: t('common.delete'),
      icon: <ExclamationCircleFilled />,
      content: t('components.CourseInstanceAttendanceListModal.deleteBatch', {
        count: selectedAttendees.length,
      }),
      okText: t('common.ok'),
      cancelText: t('common.cancel'),
      onOk() {
        deleteUsers(
          selectedAttendees.map((attendee) =>
            isMappedInElsa ? attendee.elsaId : attendee.id,
          ),
        );
      },
    });
  };

  const renderRowMenuItems = (
    id: CourseInstanceAttendee['id'] | CourseInstanceAttendee['elsaId'],
  ) => {
    return [
      {
        key: `edit-${id}`,
        label: (
          <div
            className="flex gap-2"
            onClick={() =>
              setEditAttendee(
                attendees?.find((attendee) =>
                  isMappedInElsa ? attendee.elsaId === id : attendee.id === id,
                ),
              )
            }>
            <EditOutlined style={{ color: colors.safeLifeDarker }} />
            {t('common.edit')}
          </div>
        ),
      },

      {
        key: `delete-${id}`,
        label: (
          <div className="flex gap-2" onClick={() => deleteUsers([id])}>
            <UserDeleteOutlined style={{ color: colors.warningRed }} />
            {t('common.delete')}
          </div>
        ),
      },
    ];
  };

  const columns: ColumnsType<CourseInstanceAttendee> = [
    {
      title: t('components.CourseInstanceAttendanceListModal.columnName'),
      dataIndex: 'name',
    },
    {
      title: t('components.CourseInstanceAttendanceListModal.columnEmail'),
      dataIndex: 'email',
    },
    ...(courseInstance.elsaTrainingSessionId &&
    courseInstance.hasPrerequisiteDigitalCourse
      ? [
          {
            title: t(
              'components.CourseInstanceAttendanceListModal.columnDigitalCourse',
            ),
            dataIndex: 'prerequisiteDigitalCourseCompletedPercentage',
            render: (
              prerequisiteDigitalCourseCompletedPercentage: number | undefined,
            ) => {
              if (!prerequisiteDigitalCourseCompletedPercentage) {
                return (
                  <span className="text-red-700 font-medium">
                    {t(
                      'components.CourseInstanceAttendanceListModal.notStarted',
                    )}
                  </span>
                );
              }
              if (prerequisiteDigitalCourseCompletedPercentage === 100) {
                return (
                  <span className="text-success font-medium">
                    {t(
                      'components.CourseInstanceAttendanceListModal.completed',
                    )}
                  </span>
                );
              }
              return (
                <span className="text-blueGrayDark font-medium">
                  {t(
                    'components.CourseInstanceAttendanceListModal.inProgress',
                    {
                      percentage: prerequisiteDigitalCourseCompletedPercentage,
                    },
                  )}
                </span>
              );
            },
          },
        ]
      : []),
    {
      title: t('components.CourseInstanceAttendanceListModal.columnStatus'),
      dataIndex: 'status',
      render: (status: CourseInstanceAttendeeStatus) => (
        <CourseInstanceAttendeeStatusBox status={status} />
      ),
    },
    {
      title: '',
      dataIndex: isMappedInElsa ? 'elsaId' : 'id',
      align: 'right',
      render: (
        id: CourseInstanceAttendee['id'] | CourseInstanceAttendee['elsaId'],
      ) => {
        return (
          <Dropdown
            menu={{ items: renderRowMenuItems(id) }}
            placement="bottomRight"
            trigger={['click']}>
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: CourseInstanceAttendee[],
    ) => {
      setSelectedAttendees(selectedRows);
    },
  };

  const deleteUsers = (
    idsToRemove: (
      | CourseInstanceAttendee['id']
      | CourseInstanceAttendee['elsaId']
    )[],
  ) => {
    const attendees = idsToRemove.map((id) => {
      if (typeof id === 'number') {
        return {
          id: id,
          elsaId: undefined,
        };
      } else {
        return {
          id: undefined,
          elsaId: id,
        };
      }
    });

    CourseInstanceAPI.deleteAttendees(id, attendees).then(() => {
      attendeesQuery.refetch();
      setSelectedAttendees([]);
    });
  };

  const onStatusUpdate = (newStatus?: CourseInstanceAttendeeStatus) => {
    const attendeesWithNewStatuses = selectedAttendees.map((attendee) => ({
      id: attendee.id,
      elsaId: attendee.elsaId,
      name: attendee.name,
      email: attendee.email,
      comment: attendee.comment,
      status: newStatus,
    }));
    CourseInstanceAPI.updateAttendees(id, attendeesWithNewStatuses).then(() =>
      attendeesQuery.refetch(),
    );
  };

  const batchEditMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <CourseInstanceAttendeeStatusBox
          status={CourseInstanceAttendeeStatus.Passed}
          onClick={() => onStatusUpdate(CourseInstanceAttendeeStatus.Passed)}
        />
      ),
    },
    {
      key: '2',
      label: (
        <CourseInstanceAttendeeStatusBox
          status={CourseInstanceAttendeeStatus.Failed}
          onClick={() => onStatusUpdate(CourseInstanceAttendeeStatus.Failed)}
        />
      ),
    },
    {
      key: '3',
      label: (
        <CourseInstanceAttendeeStatusBox
          status={CourseInstanceAttendeeStatus.DidNotParticipate}
          onClick={() =>
            onStatusUpdate(CourseInstanceAttendeeStatus.DidNotParticipate)
          }
        />
      ),
    },
    {
      key: '4',
      label: (
        <div className="flex gap-2" onClick={() => onStatusUpdate(undefined)}>
          <MinusCircleOutlined style={{ color: colors.safeLifeDarker }} />
          {t('components.CourseInstanceAttendanceListModal.statusClear')}
        </div>
      ),
    },
  ];

  const exampleFileName = 'SafeLife-SampleCSVFile.csv';

  const onExport = () => {
    if (!attendees) {
      return;
    }

    const csv = jsonToCSV(
      attendees.map((attendee) => ({
        name: attendee.name,
        email: attendee.email,
        status: attendee.status,
        comment: attendee.comment,
      })),
      {
        columns: ['name', 'email', 'status', 'comment'],
      },
    );
    const blob = new Blob([csv]);
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = blobUrl;
    a.download = (
      t('components.CourseInstanceAttendanceListModal.attendanceList') + '.csv'
    ).replace(' ', '-');
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      width={1000}
      title={t('components.CourseInstanceAttendanceListModal.attendanceList')}
      footer={null}>
      <div className="w-100 p-4 bg-blueGrayLightest flex rounded gap-4">
        <Button type="primary" onClick={() => setShowCreateAttendeeModal(true)}>
          {t('components.CourseInstanceAttendanceListModal.addAttendee')}
        </Button>
        <CSVReader
          config={{ header: true }}
          onUploadAccepted={({
            data,
            errors,
          }: ParseResult<ExportedCourseInstanceAttendee>) => {
            if (errors.length > 0) {
              message.error(
                <>
                  <span>{t('errors.somethingWentWrong')}</span>
                  <div>{JSON.stringify(errors)}</div>
                </>,
              );
            }

            const requestData: CourseInstanceAttendee[] = data.map((item) => ({
              id: item.id,
              name: item.name,
              email: item.email || undefined,
              status: item.status || undefined,
              comment: item.comment || undefined,
            }));

            CourseInstanceAPI.createAttendees(id, requestData)
              .then(() => attendeesQuery.refetch())
              .catch((error) => {
                if (isAxiosError(error) && !error.response?.data.message) {
                  message.error(t('errors.somethingWentWrong'));
                }
              });
          }}>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ({ getRootProps }: any) => (
              <Button icon={<ImportOutlined />} {...getRootProps()}>
                {t('components.CourseInstanceAttendanceListModal.import')}
              </Button>
            )
          }
        </CSVReader>
        <Button icon={<ExportOutlined />} onClick={onExport}>
          {t('components.CourseInstanceAttendanceListModal.export')}
        </Button>
        <div className="grow" />
        <a
          download={exampleFileName}
          href={process.env.REACT_APP_PUBLIC_URL + exampleFileName}>
          <Button icon={<DownloadOutlined />}>
            {t('components.CourseInstanceAttendanceListModal.downloadExample')}
          </Button>
        </a>
      </div>
      <div className="mt-4">
        <Table
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('common.noData')}
              />
            ),
          }}
          dataSource={attendees}
          columns={columns}
          rowKey={(record) => (isMappedInElsa ? record.elsaId : record.id)!}
          rowSelection={rowSelection}
          expandable={{
            expandedRowRender: (record) => <span>{record.comment}</span>,
            rowExpandable: (record) => !!record.comment,
          }}
          loading={attendeesQuery.isFetching}
        />
      </div>
      {selectedAttendees.length > 0 && (
        <div className="flex p-4 justify-center">
          <div
            className="rounded-lg p-4 flex gap-3 items-center"
            style={{
              backgroundColor: colors.safeLifeDarker,
              color: colors.white,
            }}>
            <div
              className="px-2 rounded"
              style={{
                backgroundColor: colors.white,
                color: colors.textDefault,
              }}>
              {selectedAttendees.length}
            </div>
            {t(
              'components.CourseInstanceAttendanceListModal.attendeesSelected',
              { count: selectedAttendees.length },
            )}
            <Dropdown
              menu={{ items: batchEditMenuItems }}
              placement="bottomRight"
              trigger={['click']}>
              <Button className="flex border-1 shadow-none rounded h-10 items-center font-medium">
                {t('components.CourseInstanceAttendanceListModal.changeStatus')}
                <DownOutlined className="h-3.5 w-3.5" />
              </Button>
            </Dropdown>
            <DownloadCertificateDropdown
              selectedAttendees={selectedAttendees}
              courseInstance={courseInstance}
            />
            <Button
              className="h-10 w-10 rounded"
              type="primary"
              onClick={showConfirmDeletion}
              icon={<UsergroupDeleteOutlined />}
            />
          </div>
        </div>
      )}
      <CreateCourseInstanceAttendeeModal
        courseInstanceId={id}
        isMappedInElsa={isMappedInElsa}
        visible={showCreateAttendeeModal}
        onCancel={() => setShowCreateAttendeeModal(false)}
        onCreateAttendees={() => attendeesQuery.refetch()}
      />
      {editAttendee && (
        <EditCourseInstanceAttendeeModal
          attendee={editAttendee}
          courseInstanceId={id}
          isMappedInElsa={isMappedInElsa}
          onEditAttendees={() => attendeesQuery.refetch()}
          onCancel={() => setEditAttendee(undefined)}
        />
      )}
    </Modal>
  );
};

export default CourseInstanceAttendanceListModal;
