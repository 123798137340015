import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import ClientCompany from '../../bookingOrders/types/ClientCompany';
import ContactPerson from '../../bookingOrders/types/ContactPerson';
import { DisplayedCourseInstance } from '../types/CourseInstance';
import Search from '../../search/component/Search';
import BookingOrderInformation from '../../bookingOrders/components/BookingOrderInformation';

type CourseInstanceAssignmentModalProps = {
  visible: boolean;
  clientCompany?: ClientCompany;
  contactPerson?: ContactPerson;
  onCancel: () => void;
  updateDisplayedCourseInstance?: (
    displayedCourseInstance: DisplayedCourseInstance,
  ) => void;
  displayedCourseInstance?: DisplayedCourseInstance;
};

const CourseInstanceAssignmentModal: React.FC<
  CourseInstanceAssignmentModalProps
> = ({
  visible,
  clientCompany,
  contactPerson,
  onCancel,
  updateDisplayedCourseInstance,
  displayedCourseInstance,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={visible}
      className="w-10/12 flex flex-col"
      onCancel={onCancel}
      footer={null}>
      <div className="text-lg font-semibold pb-3">
        {t('components.CourseInstanceAssignmentModal.changeCourseInstance')}
      </div>
      {(clientCompany || contactPerson) && (
        <div className="-mx-6">
          <BookingOrderInformation
            clientCompany={clientCompany}
            contactPerson={contactPerson}
          />
        </div>
      )}
      <Search
        displayedCourseInstance={displayedCourseInstance}
        contactPerson={displayedCourseInstance?.contactPerson}
        clientCompany={clientCompany}
        onSubmit={updateDisplayedCourseInstance}
      />
    </Modal>
  );
};

export default CourseInstanceAssignmentModal;
